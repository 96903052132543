import { graphql, Link } from "gatsby"
import React, { Component } from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"

class BlogIndex extends Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" image="https://ruairidh.dev/social.png" />

        <section className="text-gray-400 body-font bg-gray-900 h-auto pt-16 ">
          <div className="container m:px-5 py-24 mx-auto overflow-hidden">
            <div className="flex flex-wrap w-full mb-20">
              <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-white">
                  A blog about engineering
                </h1>
                <div className="h-1 w-20 bg-red-500 rounded" />
              </div>
              <p className="lg:w-1/2 w-full leading-relaxed text-gray-400 text-opacity-90">
                I want to learn in public, share things that will make your job
                easier, and have some fun while doing so.
              </p>
            </div>
            <div className="flex flex-wrap -m-4">
              {posts.map(({ node }) => {
                return (
                  <div
                    className="flex flex-none xl:w-1/4 md:w-1/2 sm:1/1 p-4"
                    key={node.fields.slug}
                  >
                    <div className="w-screen bg-gray-800 bg-opacity-40 p-6 rounded-lg">
                      {node.frontmatter.featuredImage ? (
                        <Link to={node.fields.slug} className="text-white">
                          <Img
                            className="h-40 rounded w-full object-cover object-center mb-6"
                            fluid={
                              node.frontmatter.featuredImage.childImageSharp
                                .fluid
                            }
                            alt={node.frontmatter.title}
                          />
                        </Link>
                      ) : (
                        <Link to={node.fields.slug} className="text-white">
                          <img
                            className="h-40 rounded w-full object-cover object-center mb-6"
                            src="https://source.unsplash.com/random"
                            alt={node.frontmatter.title}
                          />
                        </Link>
                      )}
                      <h3 className="tracking-widest text-red-400 text-xs font-medium title-font">
                        {node.frontmatter.date}
                      </h3>
                      <h2 className="text-lg text-white font-medium title-font mb-4">
                        <Link to={node.fields.slug} className="text-white">
                          {node.frontmatter.title || node.fields.slug}
                        </Link>
                      </h2>
                      <p
                        className="leading-relaxed text-sm"
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
        social {
          twitter
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
